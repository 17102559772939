
export async function instantiate(imports={}, runInitializer=true) {
    const cachedJsObjects = new WeakMap();
    // ref must be non-null
    function getCachedJsObject(ref, ifNotCached) {
        if (typeof ref !== 'object' && typeof ref !== 'function') return ifNotCached;
        const cached = cachedJsObjects.get(ref);
        if (cached !== void 0) return cached;
        cachedJsObjects.set(ref, ifNotCached);
        return ifNotCached;
    }


    
    const js_code = {
        'kotlin.captureStackTrace' : () => new Error().stack,
        'kotlin.wasm.internal.stringLength' : (x) => x.length,
        'kotlin.wasm.internal.jsExportStringToWasm' : (src, srcOffset, srcLength, dstAddr) => { 
            const mem16 = new Uint16Array(wasmExports.memory.buffer, dstAddr, srcLength);
            let arrayIndex = 0;
            let srcIndex = srcOffset;
            while (arrayIndex < srcLength) {
                mem16.set([src.charCodeAt(srcIndex)], arrayIndex);
                srcIndex++;
                arrayIndex++;
            }     
             },
        'kotlin.wasm.internal.externrefToBoolean' : (ref) => Boolean(ref),
        'kotlin.wasm.internal.importStringFromWasm' : (address, length, prefix) => { 
            const mem16 = new Uint16Array(wasmExports.memory.buffer, address, length);
            const str = String.fromCharCode.apply(null, mem16);
            return (prefix == null) ? str : prefix + str;
             },
        'kotlin.wasm.internal.getJsEmptyString' : () => '',
        'kotlin.wasm.internal.externrefToString' : (ref) => String(ref),
        'kotlin.wasm.internal.externrefEquals' : (lhs, rhs) => lhs === rhs,
        'kotlin.wasm.internal.externrefHashCode' : 
        (() => {
        const dataView = new DataView(new ArrayBuffer(8));
        function numberHashCode(obj) {
            if ((obj | 0) === obj) {
                return obj | 0;
            } else {
                dataView.setFloat64(0, obj, true);
                return (dataView.getInt32(0, true) * 31 | 0) + dataView.getInt32(4, true) | 0;
            }
        }
        
        const hashCodes = new WeakMap();
        function getObjectHashCode(obj) {
            const res = hashCodes.get(obj);
            if (res === undefined) {
                const POW_2_32 = 4294967296;
                const hash = (Math.random() * POW_2_32) | 0;
                hashCodes.set(obj, hash);
                return hash;
            }
            return res;
        }
        
        function getStringHashCode(str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                var code  = str.charCodeAt(i);
                hash  = (hash * 31 + code) | 0;
            }
            return hash;
        }
        
        return (obj) => {
            if (obj == null) {
                return 0;
            }
            switch (typeof obj) {
                case "object":
                case "function":
                    return getObjectHashCode(obj);
                case "number":
                    return numberHashCode(obj);
                case "boolean":
                    return obj ? 1231 : 1237;
                default:
                    return getStringHashCode(String(obj)); 
            }
        }
        })(),
        'kotlin.wasm.internal.isNullish' : (ref) => ref == null,
        'kotlin.wasm.internal.intToExternref' : (x) => x,
        'kotlin.wasm.internal.getJsTrue' : () => true,
        'kotlin.wasm.internal.getJsFalse' : () => false,
        'kotlin.wasm.internal.newJsArray' : () => [],
        'kotlin.wasm.internal.jsArrayPush' : (array, element) => { array.push(element); },
        'kotlin.wasm.internal.getCachedJsObject_$external_fun' : (p0, p1) => getCachedJsObject(p0, p1),
        'kotlin.js.jsCatch' : (f) => { 
            let result = null;
            try { 
                f();
            } catch (e) {
               result = e;
            }
            return result;
             },
        'kotlin.js.__convertKotlinClosureToJsClosure_(()->Unit)' : (f) => getCachedJsObject(f, () => wasmExports['__callFunction_(()->Unit)'](f, )),
        'kotlin.js.jsThrow' : (e) => { throw e; },
        'kotlin.io.printError' : (error) => console.error(error),
        'kotlin.io.printlnImpl' : (message) => console.log(message),
        'kotlin.js.jsArrayGet' : (array, index) => array[index],
        'kotlin.js.length_$external_prop_getter' : (_this) => _this.length,
        'kotlin.js.JsBoolean_$external_class_instanceof' : (x) => typeof x === 'boolean',
        'kotlin.js.then_$external_fun' : (_this, p0) => _this.then(p0),
        'kotlin.js.__convertKotlinClosureToJsClosure_((Js?)->Js?)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js?)->Js?)'](f, p0)),
        'kotlin.js.then_$external_fun_1' : (_this, p0, p1) => _this.then(p0, p1),
        'kotlin.js.__convertKotlinClosureToJsClosure_((Js)->Js?)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js)->Js?)'](f, p0)),
        'kotlin.js.catch_$external_fun' : (_this, p0) => _this.catch(p0),
        'kotlin.random.initialSeed' : () => ((Math.random() * Math.pow(2, 32)) | 0),
        'kotlin.wasm.internal.getJsClassName' : (jsKlass) => jsKlass.name,
        'kotlin.wasm.internal.instanceOf' : (ref, jsKlass) => ref instanceof jsKlass,
        'kotlin.wasm.internal.getConstructor' : (obj) => obj.constructor,
        'kotlin.time.tryGetPerformance' : () => typeof globalThis !== 'undefined' && typeof globalThis.performance !== 'undefined' ? globalThis.performance : null,
        'kotlin.time.getPerformanceNow' : (performance) => performance.now(),
        'kotlin.time.dateNow' : () => Date.now(),
        'kotlinx.browser.document_$external_prop_getter' : () => document,
        'kotlinx.browser.window_$external_prop_getter' : () => window,
        'org.khronos.webgl.setMethodImplForFloat32Array' : (obj, index, value) => { obj[index] = value; },
        'org.khronos.webgl.setMethodImplForUint16Array' : (obj, index, value) => { obj[index] = value; },
        'org.khronos.webgl.getMethodImplForUint8Array' : (obj, index) => { return obj[index]; },
        'org.khronos.webgl.setMethodImplForUint8Array' : (obj, index, value) => { obj[index] = value; },
        'org.khronos.webgl.getMethodImplForInt8Array' : (obj, index) => { return obj[index]; },
        'org.khronos.webgl.length_$external_prop_getter' : (_this) => _this.length,
        'org.khronos.webgl.slice_$external_fun' : (_this, p0, p1, isDefault0) => _this.slice(p0, isDefault0 ? undefined : p1, ),
        'org.khronos.webgl.Uint8Array_$external_fun' : (p0) => new Uint8Array(p0),
        'org.khronos.webgl.Uint8Array_$external_fun_1' : (p0, p1, p2, isDefault0, isDefault1) => new Uint8Array(p0, isDefault0 ? undefined : p1, isDefault1 ? undefined : p2, ),
        'org.khronos.webgl.length_$external_prop_getter_1' : (_this) => _this.length,
        'org.khronos.webgl.buffer_$external_prop_getter' : (_this) => _this.buffer,
        'org.khronos.webgl.byteOffset_$external_prop_getter' : (_this) => _this.byteOffset,
        'org.khronos.webgl.byteLength_$external_prop_getter' : (_this) => _this.byteLength,
        'org.khronos.webgl.canvas_$external_prop_getter' : (_this) => _this.canvas,
        'org.khronos.webgl.getExtension_$external_fun' : (_this, p0) => _this.getExtension(p0),
        'org.khronos.webgl.activeTexture_$external_fun' : (_this, p0) => _this.activeTexture(p0),
        'org.khronos.webgl.attachShader_$external_fun' : (_this, p0, p1) => _this.attachShader(p0, p1),
        'org.khronos.webgl.bindBuffer_$external_fun' : (_this, p0, p1) => _this.bindBuffer(p0, p1),
        'org.khronos.webgl.bindTexture_$external_fun' : (_this, p0, p1) => _this.bindTexture(p0, p1),
        'org.khronos.webgl.blendFunc_$external_fun' : (_this, p0, p1) => _this.blendFunc(p0, p1),
        'org.khronos.webgl.bufferData_$external_fun' : (_this, p0, p1, p2) => _this.bufferData(p0, p1, p2),
        'org.khronos.webgl.compileShader_$external_fun' : (_this, p0) => _this.compileShader(p0),
        'org.khronos.webgl.createBuffer_$external_fun' : (_this, ) => _this.createBuffer(),
        'org.khronos.webgl.createProgram_$external_fun' : (_this, ) => _this.createProgram(),
        'org.khronos.webgl.createShader_$external_fun' : (_this, p0) => _this.createShader(p0),
        'org.khronos.webgl.createTexture_$external_fun' : (_this, ) => _this.createTexture(),
        'org.khronos.webgl.disable_$external_fun' : (_this, p0) => _this.disable(p0),
        'org.khronos.webgl.drawElements_$external_fun' : (_this, p0, p1, p2, p3) => _this.drawElements(p0, p1, p2, p3),
        'org.khronos.webgl.enable_$external_fun' : (_this, p0) => _this.enable(p0),
        'org.khronos.webgl.enableVertexAttribArray_$external_fun' : (_this, p0) => _this.enableVertexAttribArray(p0),
        'org.khronos.webgl.generateMipmap_$external_fun' : (_this, p0) => _this.generateMipmap(p0),
        'org.khronos.webgl.getAttribLocation_$external_fun' : (_this, p0, p1) => _this.getAttribLocation(p0, p1),
        'org.khronos.webgl.getProgramParameter_$external_fun' : (_this, p0, p1) => _this.getProgramParameter(p0, p1),
        'org.khronos.webgl.getProgramInfoLog_$external_fun' : (_this, p0) => _this.getProgramInfoLog(p0),
        'org.khronos.webgl.getShaderParameter_$external_fun' : (_this, p0, p1) => _this.getShaderParameter(p0, p1),
        'org.khronos.webgl.getShaderInfoLog_$external_fun' : (_this, p0) => _this.getShaderInfoLog(p0),
        'org.khronos.webgl.getUniformLocation_$external_fun' : (_this, p0, p1) => _this.getUniformLocation(p0, p1),
        'org.khronos.webgl.linkProgram_$external_fun' : (_this, p0) => _this.linkProgram(p0),
        'org.khronos.webgl.pixelStorei_$external_fun' : (_this, p0, p1) => _this.pixelStorei(p0, p1),
        'org.khronos.webgl.shaderSource_$external_fun' : (_this, p0, p1) => _this.shaderSource(p0, p1),
        'org.khronos.webgl.texImage2D_$external_fun' : (_this, p0, p1, p2, p3, p4, p5, p6, p7, p8) => _this.texImage2D(p0, p1, p2, p3, p4, p5, p6, p7, p8),
        'org.khronos.webgl.texImage2D_$external_fun_1' : (_this, p0, p1, p2, p3, p4, p5) => _this.texImage2D(p0, p1, p2, p3, p4, p5),
        'org.khronos.webgl.texParameteri_$external_fun' : (_this, p0, p1, p2) => _this.texParameteri(p0, p1, p2),
        'org.khronos.webgl.uniform1i_$external_fun' : (_this, p0, p1) => _this.uniform1i(p0, p1),
        'org.khronos.webgl.uniformMatrix2fv_$external_fun' : (_this, p0, p1, p2) => _this.uniformMatrix2fv(p0, p1, p2),
        'org.khronos.webgl.uniformMatrix4fv_$external_fun' : (_this, p0, p1, p2) => _this.uniformMatrix4fv(p0, p1, p2),
        'org.khronos.webgl.useProgram_$external_fun' : (_this, p0) => _this.useProgram(p0),
        'org.khronos.webgl.vertexAttribPointer_$external_fun' : (_this, p0, p1, p2, p3, p4, p5) => _this.vertexAttribPointer(p0, p1, p2, p3, p4, p5),
        'org.khronos.webgl.viewport_$external_fun' : (_this, p0, p1, p2, p3) => _this.viewport(p0, p1, p2, p3),
        'org.khronos.webgl.TRIANGLES_$external_prop_getter' : (_this) => _this.TRIANGLES,
        'org.khronos.webgl.TRIANGLE_FAN_$external_prop_getter' : (_this) => _this.TRIANGLE_FAN,
        'org.khronos.webgl.SRC_ALPHA_$external_prop_getter' : (_this) => _this.SRC_ALPHA,
        'org.khronos.webgl.ONE_MINUS_SRC_ALPHA_$external_prop_getter' : (_this) => _this.ONE_MINUS_SRC_ALPHA,
        'org.khronos.webgl.ARRAY_BUFFER_$external_prop_getter' : (_this) => _this.ARRAY_BUFFER,
        'org.khronos.webgl.ELEMENT_ARRAY_BUFFER_$external_prop_getter' : (_this) => _this.ELEMENT_ARRAY_BUFFER,
        'org.khronos.webgl.STATIC_DRAW_$external_prop_getter' : (_this) => _this.STATIC_DRAW,
        'org.khronos.webgl.BLEND_$external_prop_getter' : (_this) => _this.BLEND,
        'org.khronos.webgl.DEPTH_TEST_$external_prop_getter' : (_this) => _this.DEPTH_TEST,
        'org.khronos.webgl.UNPACK_ALIGNMENT_$external_prop_getter' : (_this) => _this.UNPACK_ALIGNMENT,
        'org.khronos.webgl.UNSIGNED_BYTE_$external_prop_getter' : (_this) => _this.UNSIGNED_BYTE,
        'org.khronos.webgl.UNSIGNED_SHORT_$external_prop_getter' : (_this) => _this.UNSIGNED_SHORT,
        'org.khronos.webgl.FLOAT_$external_prop_getter' : (_this) => _this.FLOAT,
        'org.khronos.webgl.ALPHA_$external_prop_getter' : (_this) => _this.ALPHA,
        'org.khronos.webgl.RGB_$external_prop_getter' : (_this) => _this.RGB,
        'org.khronos.webgl.RGBA_$external_prop_getter' : (_this) => _this.RGBA,
        'org.khronos.webgl.FRAGMENT_SHADER_$external_prop_getter' : (_this) => _this.FRAGMENT_SHADER,
        'org.khronos.webgl.VERTEX_SHADER_$external_prop_getter' : (_this) => _this.VERTEX_SHADER,
        'org.khronos.webgl.LINK_STATUS_$external_prop_getter' : (_this) => _this.LINK_STATUS,
        'org.khronos.webgl.NEAREST_$external_prop_getter' : (_this) => _this.NEAREST,
        'org.khronos.webgl.TEXTURE_MAG_FILTER_$external_prop_getter' : (_this) => _this.TEXTURE_MAG_FILTER,
        'org.khronos.webgl.TEXTURE_MIN_FILTER_$external_prop_getter' : (_this) => _this.TEXTURE_MIN_FILTER,
        'org.khronos.webgl.TEXTURE_WRAP_S_$external_prop_getter' : (_this) => _this.TEXTURE_WRAP_S,
        'org.khronos.webgl.TEXTURE_WRAP_T_$external_prop_getter' : (_this) => _this.TEXTURE_WRAP_T,
        'org.khronos.webgl.TEXTURE_2D_$external_prop_getter' : (_this) => _this.TEXTURE_2D,
        'org.khronos.webgl.TEXTURE0_$external_prop_getter' : (_this) => _this.TEXTURE0,
        'org.khronos.webgl.CLAMP_TO_EDGE_$external_prop_getter' : (_this) => _this.CLAMP_TO_EDGE,
        'org.khronos.webgl.COMPILE_STATUS_$external_prop_getter' : (_this) => _this.COMPILE_STATUS,
        'org.khronos.webgl.Companion_$external_object_getInstance' : () => WebGLRenderingContext,
        'org.khronos.webgl.WebGLRenderingContext_$external_class_instanceof' : (x) => x instanceof WebGLRenderingContext,
        'org.khronos.webgl.Float32Array_$external_fun' : (p0) => new Float32Array(p0),
        'org.khronos.webgl.Uint16Array_$external_fun' : (p0) => new Uint16Array(p0),
        'org.khronos.webgl.length_$external_prop_getter_2' : (_this) => _this.length,
        'org.w3c.dom.encryptedmedia.__convertKotlinClosureToJsClosure_((Js)->Unit)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Js)->Unit)'](f, p0)),
        'org.w3c.dom.events.type_$external_prop_getter' : (_this) => _this.type,
        'org.w3c.dom.events.preventDefault_$external_fun' : (_this, ) => _this.preventDefault(),
        'org.w3c.dom.events.Event_$external_class_instanceof' : (x) => x instanceof Event,
        'org.w3c.dom.events.clientX_$external_prop_getter' : (_this) => _this.clientX,
        'org.w3c.dom.events.clientY_$external_prop_getter' : (_this) => _this.clientY,
        'org.w3c.dom.events.button_$external_prop_getter' : (_this) => _this.button,
        'org.w3c.dom.events.buttons_$external_prop_getter' : (_this) => _this.buttons,
        'org.w3c.dom.events.MouseEvent_$external_class_instanceof' : (x) => x instanceof MouseEvent,
        'org.w3c.dom.events.which_$external_prop_getter' : (_this) => _this.which,
        'org.w3c.dom.events.KeyboardEvent_$external_class_instanceof' : (x) => x instanceof KeyboardEvent,
        'org.w3c.dom.events.deltaY_$external_prop_getter' : (_this) => _this.deltaY,
        'org.w3c.dom.events.deltaMode_$external_prop_getter' : (_this) => _this.deltaMode,
        'org.w3c.dom.events.DOM_DELTA_PIXEL_$external_prop_getter' : (_this) => _this.DOM_DELTA_PIXEL,
        'org.w3c.dom.events.Companion_$external_object_getInstance' : () => WheelEvent,
        'org.w3c.dom.events.WheelEvent_$external_class_instanceof' : (x) => x instanceof WheelEvent,
        'org.w3c.dom.events.addEventListener_$external_fun' : (_this, p0, p1) => _this.addEventListener(p0, p1),
        'org.w3c.dom.events.removeEventListener_$external_fun' : (_this, p0, p1) => _this.removeEventListener(p0, p1),
        'org.w3c.dom.location_$external_prop_getter' : (_this) => _this.location,
        'org.w3c.dom.devicePixelRatio_$external_prop_getter' : (_this) => _this.devicePixelRatio,
        'org.w3c.dom.alert_$external_fun' : (_this, p0) => _this.alert(p0),
        'org.w3c.dom.requestAnimationFrame_$external_fun' : (_this, p0) => _this.requestAnimationFrame(p0),
        'org.w3c.dom.__convertKotlinClosureToJsClosure_((Double)->Unit)' : (f) => getCachedJsObject(f, (p0) => wasmExports['__callFunction_((Double)->Unit)'](f, p0)),
        'org.w3c.dom.width_$external_prop_getter' : (_this) => _this.width,
        'org.w3c.dom.width_$external_prop_setter' : (_this, v) => _this.width = v,
        'org.w3c.dom.height_$external_prop_getter' : (_this) => _this.height,
        'org.w3c.dom.height_$external_prop_setter' : (_this, v) => _this.height = v,
        'org.w3c.dom.getContext_$external_fun' : (_this, p0, p1) => _this.getContext(p0, ...p1),
        'org.w3c.dom.HTMLCanvasElement_$external_class_instanceof' : (x) => x instanceof HTMLCanvasElement,
        'org.w3c.dom.clientWidth_$external_prop_getter' : (_this) => _this.clientWidth,
        'org.w3c.dom.clientHeight_$external_prop_getter' : (_this) => _this.clientHeight,
        'org.w3c.dom.getBoundingClientRect_$external_fun' : (_this, ) => _this.getBoundingClientRect(),
        'org.w3c.dom.getElementById_$external_fun' : (_this, p0) => _this.getElementById(p0),
        'org.w3c.dom.value_$external_prop_setter' : (_this, v) => _this.value = v,
        'org.w3c.dom.HTMLProgressElement_$external_class_instanceof' : (x) => x instanceof HTMLProgressElement,
        'org.w3c.dom.checked_$external_prop_getter' : (_this) => _this.checked,
        'org.w3c.dom.checked_$external_prop_setter' : (_this, v) => _this.checked = v,
        'org.w3c.dom.HTMLInputElement_$external_class_instanceof' : (x) => x instanceof HTMLInputElement,
        'org.w3c.dom.host_$external_prop_getter' : (_this) => _this.host,
        'org.w3c.dom.Audio_$external_fun' : (p0, isDefault0) => new Audio(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.Audio_$external_class_instanceof' : (x) => x instanceof Audio,
        'org.w3c.dom.cloneNode_$external_fun' : (_this, p0, isDefault0) => _this.cloneNode(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.Image_$external_fun' : (p0, p1, isDefault0, isDefault1) => new Image(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, ),
        'org.w3c.dom.onchange_$external_prop_setter' : (_this, v) => _this.onchange = v,
        'org.w3c.dom.oncontextmenu_$external_prop_setter' : (_this, v) => _this.oncontextmenu = v,
        'org.w3c.dom.onkeydown_$external_prop_setter' : (_this, v) => _this.onkeydown = v,
        'org.w3c.dom.onkeyup_$external_prop_setter' : (_this, v) => _this.onkeyup = v,
        'org.w3c.dom.onmousedown_$external_prop_setter' : (_this, v) => _this.onmousedown = v,
        'org.w3c.dom.onmousemove_$external_prop_setter' : (_this, v) => _this.onmousemove = v,
        'org.w3c.dom.onmouseup_$external_prop_setter' : (_this, v) => _this.onmouseup = v,
        'org.w3c.dom.onwheel_$external_prop_setter' : (_this, v) => _this.onwheel = v,
        'org.w3c.dom.onresize_$external_prop_setter' : (_this, v) => _this.onresize = v,
        'org.w3c.dom.clearTimeout_$external_fun' : (_this, p0, isDefault0) => _this.clearTimeout(isDefault0 ? undefined : p0, ),
        'org.w3c.dom.top_$external_prop_getter' : (_this) => _this.top,
        'org.w3c.dom.left_$external_prop_getter' : (_this) => _this.left,
        'org.w3c.dom.src_$external_prop_setter' : (_this, v) => _this.src = v,
        'org.w3c.dom.data_$external_prop_getter' : (_this) => _this.data,
        'org.w3c.dom.paused_$external_prop_getter' : (_this) => _this.paused,
        'org.w3c.dom.play_$external_fun' : (_this, ) => _this.play(),
        'org.w3c.dom.binaryType_$external_prop_setter' : (_this, v) => _this.binaryType = v,
        'org.w3c.dom.close_$external_fun' : (_this, p0, p1, isDefault0, isDefault1) => _this.close(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, ),
        'org.w3c.dom.send_$external_fun' : (_this, p0) => _this.send(p0),
        'org.w3c.dom.send_$external_fun_1' : (_this, p0) => _this.send(p0),
        'org.w3c.dom.Companion_$external_object_getInstance' : () => ({}),
        'org.w3c.dom.code_$external_prop_getter' : (_this) => _this.code,
        'org.w3c.dom.reason_$external_prop_getter' : (_this) => _this.reason,
        'org.w3c.fetch.status_$external_prop_getter' : (_this) => _this.status,
        'org.w3c.fetch.statusText_$external_prop_getter' : (_this) => _this.statusText,
        'org.w3c.fetch.headers_$external_prop_getter' : (_this) => _this.headers,
        'org.w3c.fetch.body_$external_prop_getter' : (_this) => _this.body,
        'org.w3c.fetch.get_$external_fun' : (_this, p0) => _this.get(p0),
        'org.w3c.fetch.Companion_$external_object_getInstance' : () => ({}),
        'kotlinx.coroutines.tryGetProcess' : () => (typeof(process) !== 'undefined' && typeof(process.nextTick) === 'function') ? process : null,
        'kotlinx.coroutines.tryGetWindow' : () => (typeof(window) !== 'undefined' && window != null && typeof(window.addEventListener) === 'function') ? window : null,
        'kotlinx.coroutines.nextTick_$external_fun' : (_this, p0) => _this.nextTick(p0),
        'kotlinx.coroutines.error_$external_fun' : (_this, p0) => _this.error(p0),
        'kotlinx.coroutines.console_$external_prop_getter' : () => console,
        'kotlinx.coroutines.createScheduleMessagePoster' : (process) => () => Promise.resolve(0).then(process),
        'kotlinx.coroutines.__callJsClosure_(()->Unit)' : (f, ) => f(),
        'kotlinx.coroutines.createRescheduleMessagePoster' : (window) => () => window.postMessage('dispatchCoroutine', '*'),
        'kotlinx.coroutines.subscribeToWindowMessages' : (window, process) => {
            const handler = (event) => {
                if (event.source == window && event.data == 'dispatchCoroutine') {
                    event.stopPropagation();
                    process();
                }
            }
            window.addEventListener('message', handler, true);
        },
        'kotlinx.coroutines.setTimeout' : (window, handler, timeout) => window.setTimeout(handler, timeout),
        'kotlinx.coroutines.clearTimeout' : (handle) => { if (typeof clearTimeout !== 'undefined') clearTimeout(handle); },
        'kotlinx.coroutines.setTimeout_$external_fun' : (p0, p1) => setTimeout(p0, p1),
        'kotlinx.io.node.sep_$external_prop_getter' : (_this) => _this.sep,
        'kotlinx.io.node.requireExists' : () => typeof require === 'function',
        'kotlinx.io.node.requireModule' : (mod) => {
             try {
                 let m = require(mod);
                 if (m) return m;
                 return null;
             } catch (e) {
                 return null;
             }
         },
        'io.ktor.utils.io.js.decode' : (decoder, buffer) => { try { return decoder.decode(buffer) } catch(e) { return null } },
        'io.ktor.utils.io.js.tryCreateTextDecoder' : (encoding, fatal) => { try { return new TextDecoder(encoding, { fatal: fatal }) } catch(e) { return null } },
        'io.ktor.utils.io.charsets.toJsArrayImpl' : (x) => new Int8Array(x),
        'io.ktor.util.requireCrypto' : () => eval('require')('crypto'),
        'io.ktor.util.windowCrypto' : () => (window ? (window.crypto ? window.crypto : window.msCrypto) : self.crypto),
        'io.ktor.util.getRandomValues_$external_fun' : (_this, p0) => _this.getRandomValues(p0),
        'io.ktor.util.randomFillSync_$external_fun' : (_this, p0) => _this.randomFillSync(p0),
        'io.ktor.util.hasNodeApi' : () => 
        (typeof process !== 'undefined' 
            && process.versions != null 
            && process.versions.node != null) ||
        (typeof window !== 'undefined' 
            && typeof window.process !== 'undefined' 
            && window.process.versions != null 
            && window.process.versions.node != null)
        ,
        'io.ktor.util.logging.getKtorLogLevel' : () => process.env.KTOR_LOG_LEVEL,
        'io.ktor.util.logging.debug_$external_fun' : (_this, p0) => _this.debug(p0),
        'io.ktor.util.logging.console_$external_prop_getter' : () => console,
        'io.ktor.util.toJsArrayImpl' : (x) => new Int8Array(x),
        'io.ktor.util.date.Date_$external_fun' : () => new Date(),
        'io.ktor.util.date.Date_$external_fun_1' : (p0) => new Date(p0),
        'io.ktor.util.date.getTime_$external_fun' : (_this, ) => _this.getTime(),
        'io.ktor.util.date.getUTCDate_$external_fun' : (_this, ) => _this.getUTCDate(),
        'io.ktor.util.date.getUTCDay_$external_fun' : (_this, ) => _this.getUTCDay(),
        'io.ktor.util.date.getUTCFullYear_$external_fun' : (_this, ) => _this.getUTCFullYear(),
        'io.ktor.util.date.getUTCHours_$external_fun' : (_this, ) => _this.getUTCHours(),
        'io.ktor.util.date.getUTCMinutes_$external_fun' : (_this, ) => _this.getUTCMinutes(),
        'io.ktor.util.date.getUTCMonth_$external_fun' : (_this, ) => _this.getUTCMonth(),
        'io.ktor.util.date.getUTCSeconds_$external_fun' : (_this, ) => _this.getUTCSeconds(),
        'io.ktor.http.locationOrigin' : () => function() {
            var tmpLocation = null
            if (typeof window !== 'undefined') {
              tmpLocation = window.location
            } else if (typeof self !== 'undefined') {
              tmpLocation = self.location
            }
            var origin = ""
            if (tmpLocation) {
              origin = tmpLocation.origin
            }
            return origin && origin != "null" ? origin : "http://localhost"    
        }(),
        'io.ktor.client.engine.js.createBrowserWebSocket' : (urlString_capturingHack, protocols) => new WebSocket(urlString_capturingHack, protocols),
        'io.ktor.client.engine.js.createWebSocketNodeJs' : (socketCtor, urlString_capturingHack, headers_capturingHack, protocols) => new socketCtor(urlString_capturingHack, protocols, { headers: headers_capturingHack }),
        'io.ktor.client.engine.js.getKeys' : (headers) => Array.from(headers.keys()),
        'io.ktor.client.engine.js.eventAsString' : (event) => JSON.stringify(event, ['message', 'target', 'type', 'isTrusted']),
        'io.ktor.client.engine.js.compatibility.abortControllerCtorBrowser' : () => AbortController,
        'io.ktor.client.engine.js.node.bodyOn' : (body, type, handler) => body.on(type, handler),
        'io.ktor.client.engine.js.node.bodyOn_1' : (body, type, handler) => body.on(type, handler),
        'io.ktor.client.engine.js.node.pause_$external_fun' : (_this, ) => _this.pause(),
        'io.ktor.client.engine.js.node.resume_$external_fun' : (_this, ) => _this.resume(),
        'io.ktor.client.engine.js.node.destroy_$external_fun' : (_this, p0) => _this.destroy(p0),
        'io.ktor.client.fetch.body_$external_prop_setter' : (_this, v) => _this.body = v,
        'io.ktor.client.fetch.headers_$external_prop_setter' : (_this, v) => _this.headers = v,
        'io.ktor.client.fetch.method_$external_prop_setter' : (_this, v) => _this.method = v,
        'io.ktor.client.fetch.redirect_$external_prop_setter' : (_this, v) => _this.redirect = v,
        'io.ktor.client.fetch.signal_$external_prop_setter' : (_this, v) => _this.signal = v,
        'io.ktor.client.fetch.signal_$external_prop_getter' : (_this) => _this.signal,
        'io.ktor.client.fetch.abort_$external_fun' : (_this, ) => _this.abort(),
        'io.ktor.client.fetch.fetch_$external_fun' : (p0, p1, isDefault0) => fetch(p0, isDefault0 ? undefined : p1, ),
        'io.ktor.client.fetch.getReader_$external_fun' : (_this, ) => _this.getReader(),
        'io.ktor.client.fetch.cancel_$external_fun' : (_this, p0, isDefault0) => _this.cancel(isDefault0 ? undefined : p0, ),
        'io.ktor.client.fetch.read_$external_fun' : (_this, ) => _this.read(),
        'io.ktor.client.fetch.done_$external_prop_getter' : (_this) => _this.done,
        'io.ktor.client.fetch.value_$external_prop_getter' : (_this) => _this.value,
        'io.ktor.client.plugins.websocket.tryGetEventDataAsString' : (data) => typeof(data) === 'string' ? data : null,
        'io.ktor.client.plugins.websocket.tryGetEventDataAsArrayBuffer' : (data) => data instanceof ArrayBuffer ? data : null,
        'io.ktor.client.utils.makeJsObject' : () => { return {}; },
        'io.ktor.client.utils.makeRequire' : (name) => require(name),
        'io.ktor.client.utils.makeJsNew' : (ctor) => new ctor(),
        'io.ktor.client.utils.makeJsCall' : (func, arg) => func.apply(null, arg),
        'io.ktor.client.utils.setObjectField' : (obj, name, value) => obj[name]=value,
        'io.ktor.client.utils.toJsArrayImpl' : (x) => new Uint8Array(x),
        'client.adapters.readyState_$external_prop_getter' : (_this) => _this.readyState,
        'client.adapters.onopen_$external_prop_setter' : (_this, v) => _this.onopen = v,
        'client.adapters.onmessage_$external_prop_setter' : (_this, v) => _this.onmessage = v,
        'client.adapters.onclose_$external_prop_setter' : (_this, v) => _this.onclose = v,
        'client.adapters.onerror_$external_prop_setter' : (_this, v) => _this.onerror = v,
        'client.adapters.send_$external_fun' : (_this, p0) => _this.send(p0),
        'client.adapters.data_$external_prop_getter' : (_this) => _this.data,
        'client.adapters.stringify_$external_fun' : (_this, p0) => _this.stringify(p0),
        'client.adapters.parse_$external_fun' : (_this, p0) => _this.parse(p0),
        'client.adapters.JSON_$external_object_getInstance' : () => JSON,
        'client.adapters.RTCPeerConnection_$external_fun' : (p0) => new RTCPeerConnection(p0),
        'client.adapters.connectionState_$external_prop_getter' : (_this) => _this.connectionState,
        'client.adapters.localDescription_$external_prop_getter' : (_this) => _this.localDescription,
        'client.adapters.onnegotiationneeded_$external_prop_setter' : (_this, v) => _this.onnegotiationneeded = v,
        'client.adapters.onconnectionstatechange_$external_prop_setter' : (_this, v) => _this.onconnectionstatechange = v,
        'client.adapters.ondatachannel_$external_prop_setter' : (_this, v) => _this.ondatachannel = v,
        'client.adapters.onicecandidate_$external_prop_setter' : (_this, v) => _this.onicecandidate = v,
        'client.adapters.setRemoteDescription_$external_fun' : (_this, p0) => _this.setRemoteDescription(p0),
        'client.adapters.setLocalDescription_$external_fun' : (_this, p0) => _this.setLocalDescription(p0),
        'client.adapters.addIceCandidate_$external_fun' : (_this, p0) => _this.addIceCandidate(p0),
        'client.adapters.createOffer_$external_fun' : (_this, ) => _this.createOffer(),
        'client.adapters.createAnswer_$external_fun' : (_this, ) => _this.createAnswer(),
        'client.adapters.createDataChannel_$external_fun' : (_this, p0, p1) => _this.createDataChannel(p0, p1),
        'client.adapters.channel_$external_prop_getter' : (_this) => _this.channel,
        'client.adapters.candidate_$external_prop_getter' : (_this) => _this.candidate
    }
    
    // Placed here to give access to it from externals (js_code)
    let wasmInstance;
    let require; 
    let wasmExports;

    const isNodeJs = (typeof process !== 'undefined') && (process.release.name === 'node');
    const isDeno = !isNodeJs && (typeof Deno !== 'undefined')
    const isStandaloneJsVM =
        !isDeno && !isNodeJs && (
            typeof d8 !== 'undefined' // V8
            || typeof inIon !== 'undefined' // SpiderMonkey
            || typeof jscOptions !== 'undefined' // JavaScriptCore
        );
    const isBrowser = !isNodeJs && !isDeno && !isStandaloneJsVM && (typeof window !== 'undefined' || typeof self !== 'undefined');
    
    if (!isNodeJs && !isDeno && !isStandaloneJsVM && !isBrowser) {
      throw "Supported JS engine not detected";
    }
    
    const wasmFilePath = './wasmClient.wasm';
    const importObject = {
        js_code,

    };
    
    try {
      if (isNodeJs) {
        const module = await import(/* webpackIgnore: true */'node:module');
        const importMeta = import.meta;
        require = module.default.createRequire(importMeta.url);
        const fs = require('fs');
        const url = require('url');
        const filepath = import.meta.resolve(wasmFilePath);
        const wasmBuffer = fs.readFileSync(url.fileURLToPath(filepath));
        const wasmModule = new WebAssembly.Module(wasmBuffer);
        wasmInstance = new WebAssembly.Instance(wasmModule, importObject);
      }
      
      if (isDeno) {
        const path = await import(/* webpackIgnore: true */'https://deno.land/std/path/mod.ts');
        const binary = Deno.readFileSync(path.fromFileUrl(import.meta.resolve(wasmFilePath)));
        const module = await WebAssembly.compile(binary);
        wasmInstance = await WebAssembly.instantiate(module, importObject);
      }
      
      if (isStandaloneJsVM) {
        const wasmBuffer = read(wasmFilePath, 'binary');
        const wasmModule = new WebAssembly.Module(wasmBuffer);
        wasmInstance = new WebAssembly.Instance(wasmModule, importObject);
      }
      
      if (isBrowser) {
        wasmInstance = (await WebAssembly.instantiateStreaming(fetch(wasmFilePath), importObject)).instance;
      }
    } catch (e) {
      if (e instanceof WebAssembly.CompileError) {
        let text = `Please make sure that your runtime environment supports the latest version of Wasm GC and Exception-Handling proposals.
For more information, see https://kotl.in/wasm-help
`;
        if (isBrowser) {
          console.error(text);
        } else {
          const t = "\n" + text;
          if (typeof console !== "undefined" && console.log !== void 0) 
            console.log(t);
          else 
            print(t);
        }
      }
      throw e;
    }
    
    wasmExports = wasmInstance.exports;
    if (runInitializer) {
        wasmExports._initialize();
    }

    return { instance: wasmInstance,  exports: wasmExports };
}
